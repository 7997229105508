.ant-layout {
  background-color: #FEFCFD !important;
}
.ant-layout-header {
  background-color: #FEFCFD !important;
}
.da-bg-black-0 {
background-color: #FEFCFD !important;
}


.loginpage{
  background-image: url("./../assets//img/loginbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1000px;
  overflow: hidden;
}
.loginbox {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  margin-top: 150px;
  padding: 50px;
}

.loginpage h2{
  
    color: #fff;
    font-family: Work Sans, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
}
.da-sidebar .ant-layout-sider-children .ant-menu-root {
background: #F8F7F8 !important;
}
.ant-menu-sub.ant-menu-inline {
  background: #F8F7F8 !important;
}

.mainbox {
  background-color: #FEFCFD !important;
}
.mainboxCard{
  background-color: #EFF5E9 !important;
}

.mainbox h3{
  color: #495057;
  padding-left: 20px;
  padding-bottom: 20px;
}

.arriveBox{
  -webkit-box-shadow: 0px 0px 10px rgb(0 0 0 / 4%);
  background: #FFFFFF;
  height: 500px !important;
}

.bookingBox{
  -webkit-box-shadow: 0px 0px 10px rgb(0 0 0 / 4%);
}

.ant-menu-title-content{
  margin-left: 30px;
  color: #1D1D29;
}
.ant-menu-title-content a{
  color: #1D1D29;

}

.color-lightgrey{
  color: #9c9c9c;
  font-weight: 400;
}

.avatar{
    padding: 0.25rem;
    background-color: #f5f6f8;
    border: 1px solid #f6f6f6;
    border-radius: 50%;
    width: 100px;
    max-width: 100%;
    height: 100px;
}

.borderbottom {
  border-bottom: 1px solid #e9ecef;
}
.borderright {
  border-right: 1px solid #e9ecef;
}

.rightBox {
  padding: 20px;
  background-color: #FEFDFE;
  background-clip: border-box;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 4%);
}

.ant-tabs-nav-wrap{
    background-color: #FEFDFE;
    padding: 20px;
    background-clip: border-box;
    border: 1px solid #f6f6f6;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 4%);
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .remix-icon,
.ant-menu-submenu-title .remix-icon {
  font-size: 17px;
  color: #1D1D29;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
height: 35px;
}

@media (max-width: 767px) {

  .width-100{
    width: 100%;
  }

  .mobilecalender {
    width: 200vw !important;
    height: 100vh !important;
  }

  .mobilefilterbtn{
    width: 100%;
    text-align: center;
  }

  .dasboardpet {
    width: 120vw;
    overflow: scroll;
  }

}
.active-menu-item Icon{
  color: red;
}
.active-menu-item  a{
  /* color: red; */
}

.daycareplan{
  padding: 0px !important;
}
.daycareplan td{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media print {
  .hide-in-print {
    display: none;
  }

  .npm {
    padding: 0px !important;
    margin: 0px !important;
  }
}