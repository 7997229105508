.icon-style {
    font-size: 20px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 15px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
display: block !important;
}
.ant-input[disabled] {
    color: #a4a9ab !important;
    background-color: #f9f9f9 !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #a4a9ab !important;
    background-color: #f9f9f9 !important;
}
.rdw-editor-wrapper {
    border: 1px solid #dfe6e9 !important;
}